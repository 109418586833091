import React from 'react'
// import Footer from "./footer"
// import Navbar from "./navbar"

const Layout = ({ children }) => {
  return (
    <div className="global flex min-h-screen flex-col justify-between ">
      <div>
        {/* <Navbar /> */}
        {children}
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Layout
